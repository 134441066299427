<template>
  <div>
    <vm-table ref="vmTable" v-loading="loading" :filter.sync="search" url="fileManages">
      <template slot="adSearch">
        <vm-search :label="$l('maintWorkOrder.filter', '关键字检索')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
        </vm-search>
      </template>
      <template slot="toolbar">
        <el-button class="addBtn" @click="$refs.editPage.open(0)">
          {{$l("elevatorNetApply.add", "+ 新增")}}
        </el-button>
      </template>
      <el-table-column prop="name" label="名称" align="center"></el-table-column>
      <el-table-column prop="qrCode" label="二维码" align="center">
        <template v-if="scope.row.qrCode" slot-scope="scope">
          <el-popover popper-class="qrcode-popover" trigger="hover" placement="right">
            <vue-qr
              :text="scope.row.qrCode"
              :logo-scale="0.3"
              :logo-margin="5"
              :correct-level="3"
              :margin="5"
              :size="200"></vue-qr>
            <div slot="reference">
              <el-link type="info" :underline="false">{{scope.row.qrCode}}</el-link>
            </div>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="状态" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.status===0">
            <el-tag type="success">有效</el-tag>
          </span>
          <span v-else>
            <el-tag type="warning">作废</el-tag>
          </span>
        </template>
      </el-table-column>
      <el-table-column
        v-if="editAuth"
        :label="$t('common.operation')"
        width="100"
        align="center"
        fixed="right">
        <template slot-scope="scope">
          <el-button type="text" @click="handleEdit(scope.row)">{{$t("common.edit")}}</el-button>
          <el-button type="text" class="operateDelBtn" @click="handleDelete(scope.row)">
            {{$t("common.delete")}}
          </el-button>
        </template>
      </el-table-column>
    </vm-table>
    <edit-dialog ref="editPage" @save-success="getList(-1)"></edit-dialog>
  </div>
</template>
<script>
  import EditDialog from "./FileManageEdit.vue";
  import VueQr from "vue-qr";


  export default {
    components: {EditDialog, VueQr},
    data() {
      return {
        editAuth: this.$auth(833),
        photoVisible: false,
        bigImgUrl: "",
        loading: false,
        search: {
          filter: "",
        },
      };
    },
    mounted() {
      this.getList(1);
    },
    methods: {
      getList(pageNum) {
        this.loading = false;
        this.$refs.vmTable.getList(pageNum);
      },
      showBigImage(e) {
        //点击图片函数，点击后，把photoVisible设置成true
        if (e !== "") {
          this.photoVisible = true;
          this.bigImgUrl = e.currentTarget.src;
        }
      },
      handleAdd() {
        this.$refs.editPage.open(0);
      },
      handleEdit(row) {
        this.$refs.editPage.open(row.id);
      },
      handleDelete(row) {
        this.$confirm(this.$t("common.delete") + row.name + "," + this.$t("common.isDelete") + "?", this.$t("common.tips"), {
          confirmButtonText: this.$t("common.confirm"),
          cancelButtonText: this.$t("common.cancel"),
          type: "warning",
        }).then(() => {
          this.$http.delete("fileManages/" + row.id).then(res => {
            this.getList(-1);
            this.$message.success(this.$t("common.tip.deleteSuccess"));
          }).catch(() => {
            this.$message.error(this.$t("common.tip.deleteError"));
          });
        });
      },
    },
  };
</script>
<style lang="scss" scoped></style>
